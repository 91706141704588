import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../components/HeadHelmet/HeadHelmet";
import PricingTable from "../sections/snorefreeswiss/PricingTable";

import imgFeatured from "../assets/image/snorefreeswiss/bite-pricing.jpg";

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='pricing_title'
          description='pricing_s1_b'
          image={imgFeatured}
        />
        <div className="inner-banner pt-26 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <h1 className="title gr-text-2 mb-9 heading-color">
                    {t('pricing_title')}
                  </h1>
                  {/* ttt </p> */}
                </div>
              </Col>
              <Col xs="10">
                <div className="pt-lg-9 pb-8">
                  <img src={imgFeatured} alt={t('pricing_title')} className="w-100" />
                </div>
              </Col>

            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('pricing_s1_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('pricing_s1_b')} </p>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('pricing_s2_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('pricing_s1_b')} </p>
                  </div>
                  <div className="single-block mb-11">
                    <h2 className="gr-text-8 fw-bold pb-3 heading-color">{t('pricing_s3_t')}</h2>
                    <p className="gr-text-9 text-color-opacity">
                      {t('pricing_s3_b')} </p>
                  </div>

                  {/* <Link
                    to="/contact"
                    className="btn btn-blue text-white gr-hover-y px-lg-9"
                  >
                    {t('pricing_cta')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link> */}
                  

                </div>
              </Col>
            </Row>
          </Container>
          <PricingTable/>
        </div>
      </PageWrapper>
    </>
  );
};
export default Pricing;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
